import { Dot } from '@pp/components/Dot'
import { getReadingTime } from '@pp/utils/text'
import { Text } from '@prontopro/ui-toolkit'
import React from 'react'

type Props = {
  date: string
  wordCount: {
    words: number
  }
}

export const Info = ({ date, wordCount }: Props) => {
  const readTime = getReadingTime(wordCount.words)

  return (
    <>
      <Text kind="bodyM" colorName="gray300">
        {date}
        {' '}
        <Dot />
        {readTime}
        min
      </Text>
    </>
  )
}
