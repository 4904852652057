import { Content } from '@pp/components/Content'
import { Hero } from '@pp/components/Hero'
import { Layout } from '@pp/components/Layout'
import { MaxWidth1200 } from '@pp/components/MaxWidth'
import { Post } from '@pp/components/Post'
import { SEO } from '@pp/components/SEO'
import { MarkdownRemarkData } from '@pp/types/data/markdownRemark'
import { Flex, Text } from '@prontopro/ui-toolkit'
import { graphql, Link } from 'gatsby'
import React from 'react'
import { Info } from './Info'

type Props = {
  data: MarkdownRemarkData<{
    excerpt: string
    frontmatter: {
      author: {
        firstName: string
        github: string
        lastName: string
        twitter: string | null
      }
      date: string
      slug: string
      title: string
    }
    internal: {
      content: string
    }
    wordCount: {
      words: number
    }
  }>
}

const PostTemplate = ({ data }: Props) => {
  const { github, twitter } = data.markdownRemark.frontmatter.author

  const authorLink = twitter ? `https://twitter.com/${twitter}` : `https://github.com/${github}`

  return (
    <Layout>
      <SEO title={data.markdownRemark.frontmatter.title} description={data.markdownRemark.excerpt} />

      <MaxWidth1200>
        <Hero>
          <Text kind="headingL" colorName="darkGreen600">
            {data.markdownRemark.frontmatter.title}
          </Text>
          <Flex pt={16}>
            <Info date={data.markdownRemark.frontmatter.date} wordCount={data.markdownRemark.wordCount} />
          </Flex>
          <Flex pt={16}>
            <Text kind="bodyMBold">
              Author:&nbsp;
              <Link to={authorLink} target="_blank">
                <Text kind="bodyMBold" colorName="darkGreen300">
                  {data.markdownRemark.frontmatter.author.firstName}
                  &nbsp;
                  {data.markdownRemark.frontmatter.author.lastName}
                </Text>
              </Link>
            </Text>
          </Flex>
        </Hero>

        <Content>
          <Flex pb={48}>
            <Post markdown={data.markdownRemark.internal.content} />
          </Flex>
        </Content>
      </MaxWidth1200>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      excerpt
      frontmatter {
        author {
          firstName
          github
          lastName
          twitter
        }
        date(formatString: "MMMM DD, YYYY")
        slug
        title
      }
      internal {
        content
      }
      wordCount {
        words
      }
    }
  }
`

export default PostTemplate
