import React from 'react'
import ReactMarkdown from 'react-markdown'
import { Blockquote } from './Blockquote'
import { Break } from './Break'
import { Code } from './Code'
import { Em } from './Em'
import { Heading } from './Heading'
import { Hr } from './Hr'
import { Image } from './Image'
import { InlineCode } from './InlineCode'
import { Link } from './Link'
import { List } from './List'
import { ListItem } from './ListItem'
import { Paragraph } from './Paragraph'
import { Strong } from './Strong'

type Props = {
  markdown: string
}

const renderers = {
  blockquote: Blockquote,

  code: Code,

  emphasis: Em,

  heading: Heading,

  html: Break,

  image: Image,

  inlineCode: InlineCode,

  link: Link,

  list: List,

  listItem: ListItem,

  paragraph: Paragraph,

  strong: Strong,

  thematicBreak: Hr,
}

export const Post = ({ markdown }: Props) => <ReactMarkdown renderers={renderers}>{markdown}</ReactMarkdown>
