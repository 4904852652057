import React, { ReactNode } from 'react'
import { Flex, Text } from '@prontopro/ui-toolkit'

type Props = {
  level: number
  children: ReactNode
}

const switchHeading = (level: number) => {
  switch (level) {
    case 1:
      return 'h1'
    case 2:
      return 'h2'
    case 3:
      return 'h3'
    default:
      return 'h4'
  }
}

const switchKind = (level: number) => {
  switch (level) {
    case 1:
      return 'headingL'
    case 2:
      return 'headingM'
    case 3:
      return 'headingS'
    default:
      return 'headingXS'
  }
}

export const Heading = ({ level, children }: Props) => (
  <Flex as={switchHeading(level)} pt={16} pb={4}>
    <Text kind={switchKind(level)} colorName="darkGreen900">
      {children}
    </Text>
  </Flex>
)
