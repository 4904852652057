import { Text } from '@prontopro/ui-toolkit'
import { ColorName } from '@prontopro/ui-toolkit/typings/tokens/colors'
import React, { ReactNode } from 'react'

type Props = {
  to: string
  children: string | ReactNode
  color?: ColorName
}

export const ExternalLink = ({ to, children, color = 'black' }: Props) => (
  <a href={to} rel="noopener noreferrer" target="_blank">
    <Text kind="bodyM" colorName={color} textDecoration="underline">
      {children}
    </Text>
  </a>
)
