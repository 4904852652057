import React from 'react'

type Props = { value: string }

export const Break = ({ value }: Props) => {
  if (value === '<br />' || value === '<br/>') {
    return <br />
  }

  return null
}
