import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Flex } from '@prontopro/ui-toolkit'
import styled from 'styled-components'

const ImgMaxWidth100 = styled.img`
  max-width: 100%;
`

const ExternalImage = ({ alt, src }: any) => (
  <Flex as="span" py={4} justify="center" align="center">
    <ImgMaxWidth100 src={src} alt={alt} />
  </Flex>
)

const InternalImage = ({ name }: any) => {
  const nameWithoutExtension = name.replace(/.jpg|.png|.jpeg/, '')

  const data = useStaticQuery(graphql`
    query {
      images: allFile(filter: { extension: { regex: "/jpeg|jpg|png|gif/" } }) {
        nodes {
          name
          relativePath
          ext
          publicURL
          base
          dir
        }
      }
    }
  `)

  const image = data.images.nodes.find((imageNode: any) => imageNode.name === nameWithoutExtension)

  if (!image) {
    throw Error(`No image "${name}" found`)
  }

  return (
    <Flex as="span" py={4} justify="center" align="center">
      <ImgMaxWidth100 src={image.publicURL} alt={image.alt} />
    </Flex>
  )
}

type Props = {
  src: string
  alt: string
}

export const Image = ({ alt, src }: Props) => {
  const isExternal = src.match(/^https?:\/\//)

  if (isExternal) {
    return <ExternalImage alt={alt} src={src} />
  }

  return <InternalImage name={src} />
}
