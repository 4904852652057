import { Flex, Text } from '@prontopro/ui-toolkit'
import React, { ReactNode } from 'react'

type Props = {
  children: ReactNode
}

export const ListItem = ({ children }: Props) => (
  <li>
    <Flex pb={8}>
      <Text kind="bodyM" colorName="darkGreen900">
        {children}
      </Text>
    </Flex>
  </li>
)
