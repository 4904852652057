import { Text } from '@prontopro/ui-toolkit'
import React, { ReactNode } from 'react'

type Props = {
  children: ReactNode
}

export const Strong = ({ children }: Props) => (
  <Text colorName="darkGreen900" kind="bodyMBold">
    {children}
  </Text>
)
