import React, { ReactNode } from 'react'
import { Flex } from '@prontopro/ui-toolkit'
import styled from 'styled-components'

export const ListComponent = styled(Flex).attrs(() => ({
  as: 'ul',
}))`
  list-style-type: circle;
  padding-inline-start: 40px;
`

type Props = {
  children: ReactNode
}

export const List = ({ children }: Props) => (
  <Flex pt={8}>
    <ListComponent>{children}</ListComponent>
  </Flex>
)
