import React from 'react'
import styled from 'styled-components'

type Props = {
  children: string
}

const InlineCodeWrapper = styled.span`
  code.language-text {
    font-size: 0.9em;
  }
`

export const InlineCode = ({ children }: Props) => (
  <InlineCodeWrapper>
    <code className="language-text">{children}</code>
  </InlineCodeWrapper>
)
