import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { colors, media } from '@prontopro/ui-toolkit'
import { useViewportDimensions } from '@prontopro/react'

const WrapperBlockquote = styled.blockquote`
  color: ${colors.gray300};
  display: flex;
  margin: 0;
  padding: 0;
  quotes: '“' '”' '‘' '’';

  &:before {
    content: open-quote;
    font-size: 37px;
    line-height: 1.2em;

    ${media.large`
      font-size: 47px;
      line-height: 74px;
    `}
  }

  &:after {
    align-self: flex-end;
    content: close-quote;
    font-size: 37px;
    line-height: 1.2em;

    ${media.large`
      font-size: 47px;
      line-height: 74px;
    `}
  }
`

type Props = {
  children: ReactNode
}

export const Blockquote = ({ children }: Props) => {
  const { windowWidth } = useViewportDimensions()

  return (
    <WrapperBlockquote windowWidth={windowWidth}>
      <span>{children}</span>
    </WrapperBlockquote>
  )
}
