import React, { useEffect } from 'react'
import Prism from 'prismjs'

type Props = {
  value: string
  language: string
}

export const Code = ({ value, language }: Props) => {
  useEffect(() => {
    // call the highlightAll() function to style our code blocks
    Prism.highlightAll()
  }, [])

  return (
    <div className="code-container">
      <pre>
        <code className={`language-${language}`}>{value}</code>
      </pre>
    </div>
  )
}
