import { InternalLink } from '@pp/ui/InternalLink'
import { ExternalLink } from '@pp/ui/ExternalLink'
import React, { ReactNode } from 'react'

type Props = {
  href: string
  children: ReactNode
}

export const Link = ({ href, children }: Props) => {
  const isExternal = href.match(/^https?:\/\//)

  if (isExternal) {
    return (
      <ExternalLink to={href} color="darkGreen300">
        {children}
      </ExternalLink>
    )
  }

  return (
    <InternalLink to={href} color="darkGreen300">
      {children}
    </InternalLink>
  )
}
