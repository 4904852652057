import { ReactNode } from 'react'
import styled from 'styled-components'

type Props = { children: ReactNode }

export const Em = styled.em.attrs(({ children }: Props) => ({
  children,
}))`
  font-style: italic;
`
