import React, { ReactNode } from 'react'
import { Flex, Text } from '@prontopro/ui-toolkit'

type Props = { children: ReactNode }

export const Paragraph = ({ children }: Props) => (
  <Flex as="p" py={8}>
    <Text kind="bodyM" colorName="darkGreen900">
      {children}
    </Text>
  </Flex>
)
